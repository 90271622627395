import { useEffect, useState } from "react";
import { providers, Contract } from "ethers";

/**
 * Check MetaMask settings
 *
 */
const _isMetaMaskInstalled = (): boolean => {
  // Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window as any;
  return Boolean(ethereum && ethereum.isMetaMask);
};

/**
 * Get metamask as provider if it exists
 */
const _getProvider = (): providers.Web3Provider | null => {
  if (!_isMetaMaskInstalled()) {
    return null;
  }
  return new providers.Web3Provider((window as any).ethereum);
};

/**
 * Get the id of the chain the user is connected to
 */
export const _getChainId = async (): Promise<string> => {
  const provider = _getProvider();
  if (!provider) {
    return "-1";
  }
  const network = await provider.getNetwork();
  return String(network.chainId);
};

/**
 * Listen for MetaMask account change and invoke callback when it changes
 *
 * @param callback method to invoke when account changes
 */
const _onAccountChange = (callback: (accounts: string[]) => void): void => {
  if (!_isMetaMaskInstalled()) {
    return;
  }
  (window as any).ethereum.on("accountsChanged", callback);
};

/**
 * Listen for MetaMask chain id change and invoke callback when it changes
 *
 * @param callback method to invoke when chain changes
 */
const _onNetworkChange = (callback: (id: string) => void): void => {
  if (!_isMetaMaskInstalled()) {
    return;
  }
  (window as any).ethereum.on("chainChanged", callback);
};

/**
 * A use effect that pulls in the address and chain id
 *
 */
export const useNetworkAddress = () => {
  const [userWalletAddress, setUserWalletAddress] = useState<string | null>(
    null
  );
  const [chainId, setChainId] = useState<string | null>(null);

  useEffect(() => {
    const loadConnection = async () => {
      try {
        const addressId = await getWalletAddress();
        if (addressId) {
          setUserWalletAddress(addressId.toLowerCase());
        }
        setChainId(String(await _getChainId()));
      } catch (error: any) {
        console.log(error);
        return error;
      }
    };
    _onAccountChange((_address) => {
      setUserWalletAddress(_address[0]);
    });
    _onNetworkChange((_network) => {
      setChainId(String(_network));
    });
    loadConnection();
  }, []);

  return {
    userWalletAddress,
    chainId,
  };
};

interface ConnectMetaMaskResponse {
  result: boolean;
  message: string;
}
/**
 * Connect Metamask wallet to page
 */
export const connectMetamask = async (): Promise<ConnectMetaMaskResponse> => {
  if (!_isMetaMaskInstalled()) {
    return {
      result: false,
      message: "Metamask is not installed. Please download MetaMask.",
    };
  }
  try {
    // Will Start the MetaMask Extension
    await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    return {
      result: true,
      message: "Successfully connected MetaMask.",
    };
  } catch (error: any) {
    console.log(error);
    let message =
      "Request error in MetaMask. Please open MetaMask and check there.";

    if (!!error && !!error.code) {
      if (error.code === 4001) {
        message =
          "MetaMask request rejected. Please click connect again and approve on MetaMask.";
      } else if (error.code === -32002) {
        message =
          "There is a already a MetaMask connection request. Please open MetaMask and check there.";
      } else {
        const contractErrorMessage =
          "data" in error &&
          !!error.data &&
          "message" in error.data &&
          !!error.data.message
            ? error.data.message
            : error.message;
        message = `Contract error - ${contractErrorMessage}`;
      }
    }
    return {
      result: false,
      message,
    };
  }
};

/**
 * Returns the contract
 */
export const getContract = (contactAddress: string, contractAbi: any[]) => {
  const provider = _getProvider();
  if (!provider) {
    return {
      message: "Unable to connect to MetaMask. Wallet not connected.",
      contract: null,
    };
  }

  const signer = provider.getSigner();
  const contract = new Contract(contactAddress, contractAbi, signer);

  return {
    message: "Success",
    contract,
  };
};

/**
 * Returns Wallet address if one is connected to the site, otherwise null
 */
export const getWalletAddress = async (): Promise<string | null> => {
  const provider = _getProvider();
  if (!provider) {
    return null;
  }
  try {
    const accounts = await provider.listAccounts();
    return accounts.length > 0 ? accounts[0] : null;
  } catch (e) {
    return null;
  }
};

/**
 *
 * @param transactionHash transaction hash
 */
export const watchTransaction = (
  transactionHash: string,
  callback: any
): void => {
  const provider = _getProvider();
  if (!provider) {
    return;
  }
  provider.once(transactionHash, (transaction) => {
    callback(transaction, transaction.status === 1);
  });
};
