import { BigNumber } from "ethers";

import dropbearsAbi from "../interfaces/dropbears.abi";
import { CONTRACT_ADDRESS_DBS } from "../env";

import { getContract } from "./_.blockchain";

// Get all the bears a particular owner has
export const getBearOwners = async (userWalletAddress: string | null) => {
  const { message, contract: contractObject } = getContract(
    CONTRACT_ADDRESS_DBS,
    dropbearsAbi
  );

  if (!!contractObject && !!userWalletAddress) {
    try {
      const transactionDetails = await contractObject.walletOfOwner(
        userWalletAddress
      );

      return {
        result: true,
        message: "Success",
        transactionDetails: transactionDetails.map((currentBearId: BigNumber) =>
          currentBearId.toString()
        ),
      };
    } catch (error: any) {
      console.log(error);
      let message =
        "Request error in MetaMask. Please open MetaMask and check there.";
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};
