import { BigNumber } from "ethers";

import bushchookAbi from "../interfaces/bushchook.abi";
import { CONTRACT_ADDRESS_DBBC } from "../env";

import { getContract } from "./_.blockchain";

// Get all the bears that have been claimed
export const getOwnerClaimedBears = async (
  userWalletAddress: string | null
) => {
  const { message, contract: contractObject } = getContract(
    CONTRACT_ADDRESS_DBBC,
    bushchookAbi
  );

  if (!!contractObject && !!userWalletAddress) {
    try {
      const transactionDetails = await contractObject.walletOfOwnerBearsClaimed(
        userWalletAddress
      );

      return {
        result: true,
        message: "Success",
        transactionDetails: transactionDetails.map((currentBearId: BigNumber) =>
          currentBearId.toString()
        ),
      };
    } catch (error: any) {
      console.log(error);
      let message =
        "Request error in MetaMask. Please open MetaMask and check there.";
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};

// Get all the bears that have been claimed
export const hasBearBeenUsedToClaim = async (bearId: number | null) => {
  const { message, contract: contractObject } = getContract(
    CONTRACT_ADDRESS_DBBC,
    bushchookAbi
  );

  if (!!contractObject && !!bearId) {
    try {
      const transactionDetails = await contractObject.hasBearBeenUsedToClaim(
        bearId
      );

      return {
        result: true,
        message: "Success",
        transactionDetails,
      };
    } catch (error: any) {
      console.log(error);
      let message = "Error getting the claimed status.";
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};

// Get all the bears that have been claimed
export const initiateClaimBushChook = async (
  userWalletAddress: string | null,
  bearIds: number[] | null
) => {
  const { message, contract: contractObject } = getContract(
    CONTRACT_ADDRESS_DBBC,
    bushchookAbi
  );

  if (!!contractObject && !!bearIds && !!userWalletAddress) {
    try {
      const transactionDetails = await contractObject.mint(
        userWalletAddress,
        bearIds
      );

      return {
        result: true,
        message: "Success",
        transactionDetails,
      };
    } catch (error: any) {
      console.log(error);
      let message = "Error claiming a Bush Chook.";

      if (!!error && !!error.code) {
        if (error.code === 4001) {
          message =
            "MetaMask request rejected. Try to claim and approve again on MetaMask.";
        } else {
          const contractErrorMessage =
            "data" in error &&
            !!error.data &&
            "message" in error.data &&
            !!error.data.message
              ? error.data.message
              : error.message;

          message = contractErrorMessage;
          if (
            message.indexOf("Pausable: paused") > -1 ||
            message.indexOf("paused") > -1
          ) {
            message = `The contract is currently locked.`;
          } else if (
            message.indexOf("Claiming: ended") ||
            message.indexOf("Claiming: stopped")
          ) {
            message = `Claiming of Bush Chooks has stopped.`;
          }
        }
      }

      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};
