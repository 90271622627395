import React, { useEffect, useState } from "react";
import axios from "axios";
import idx from "idx";

import { Button, Snackbar, TextField } from "@material-ui/core";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";

import "./App.css";

import middleButtonImage from "./assets/images/middle_button.png";
import smallButtonImage from "./assets/images/small_button.png";

import middleButtonImagePressed from "./assets/images/middle_button-pressed.png";
import smallButtonImagePressed from "./assets/images/small_button-pressed.png";

import loadingBgImage from "./assets/images/loading_bg.gif";
import loadingKoalaImage from "./assets/images/loading_koala.gif";
import noBushChookImage from "./assets/images/emu_silo.png";

import { CHAIN_ID, CHAIN_NAME, FETCH_IMAGE_URL } from "./env";
import { LoadingElement } from "./components/utils";
import {
  connectMetamask,
  useNetworkAddress,
  watchTransaction,
} from "./utils/_.blockchain";
import { getBearOwners } from "./utils/dbs.blockchain";
import {
  getOwnerClaimedBears,
  hasBearBeenUsedToClaim,
  initiateClaimBushChook,
} from "./utils/dbbc.blockchain";

export const addressStringReduction = (str: any) => {
  let firstChunk = str.substring(0, 7);
  let lastChunk = str.substring(str.length - 5);

  return `${firstChunk}...${lastChunk}`;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface BearClaimElement {
  bearId: number;
  isClaimed: boolean;
}

function App() {
  const { userWalletAddress, chainId } = useNetworkAddress();

  const [currentCheckedBearId, setCurrentCheckedBearId] = useState<
    number | null
  >();
  const [currentCheckedBearImage, setCurrentCheckedBearImage] = useState<
    string | null
  >();
  const [walletOwnersBears, setWalletOwnersBears] = useState<
    BearClaimElement[]
  >([]);
  const [selectedBearsToClaim, setSelectedBearsToClaim] = useState<number[]>(
    []
  );

  const [isBearClaimed, setIsBearClaimed] = useState(false);

  const [isBearsImageLoaded, setIsBearsImageLoaded] = useState(false);
  const [isLoadingBears, setIsLoadingBears] = useState(true);
  const [isLoadingCheckBear, setIsLoadingCheckBear] = useState(false);
  const [isLoadingClaimBushChook, setIsLoadingClaimBushChook] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);

  const [loadingClaimMessage, setLoadingClaimMessage] = useState("");
  const [updateButtonText, setUpdateButtonText] = useState("Claim!");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<Color>("warning");

  const updateBearsListing = async (userWalletAddress: string) => {
    setIsLoadingBears(true);

    const [ownersBearsResults, claimedBearsResults] = await Promise.all([
      getBearOwners(userWalletAddress),
      getOwnerClaimedBears(userWalletAddress),
    ]);

    if (ownersBearsResults.result && claimedBearsResults.result) {
      const claimedBearsMap: any = {};
      claimedBearsResults.transactionDetails.forEach(
        (currentClaimedBearId: string) => {
          // If the bear is not cliamed, it will have a bear id of 0 so skip those
          if (parseInt(currentClaimedBearId, 10)) {
            claimedBearsMap[currentClaimedBearId] = true;
          }
        }
      );
      const ownersBearListing: BearClaimElement[] =
        ownersBearsResults.transactionDetails.map((ownerBearId: string) => ({
          bearId: parseInt(ownerBearId, 10),
          isClaimed: !!claimedBearsMap[ownerBearId],
        }));
      ownersBearListing.sort((a, b) => a.bearId - b.bearId);

      setWalletOwnersBears(ownersBearListing);
    }

    setIsLoadingBears(false);
  };

  useEffect(() => {
    if (userWalletAddress) {
      updateBearsListing(userWalletAddress);
    }
  }, [userWalletAddress, chainId]);

  const handleCheckBearClick = async () => {
    if (currentCheckedBearId) {
      setIsLoadingCheckBear(true);
      setCurrentCheckedBearImage(null);

      const [hashResponse, bearClaimStatus] = await Promise.all([
        axios.get(`${FETCH_IMAGE_URL}${currentCheckedBearId}`),
        hasBearBeenUsedToClaim(currentCheckedBearId),
      ]);

      const fetchedBearImage = idx(hashResponse, (_: any) => _.data.image);
      if (fetchedBearImage && bearClaimStatus.result) {
        setIsBearsImageLoaded(false);
        setCurrentCheckedBearImage(fetchedBearImage);
        setIsBearClaimed(bearClaimStatus.transactionDetails);
        setIsLoadingCheckBear(false);
      }
    }
  };

  const handleClaimClick = async () => {
    setIsLoadingClaimBushChook(true);
    setUpdateButtonText("Claiming...");
    setAlertType("warning");

    if (chainId !== CHAIN_ID) {
      setUpdateButtonText("Claim!");
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
      setIsLoadingClaimBushChook(false);
    } else if (!userWalletAddress) {
      setUpdateButtonText("Claim!");
      setAlertMessage("There needs to be a connected wallet.");
      setShowAlert(true);
      setIsLoadingClaimBushChook(false);
    } else {
      setLoadingClaimMessage("Waiting for MetaMask approval...");
      const initiateClaimBushChookTransaction = await initiateClaimBushChook(
        userWalletAddress,
        selectedBearsToClaim
      );

      setLoadingClaimMessage("Waiting for block confirmations...");
      const { message, result, transactionDetails }: any =
        initiateClaimBushChookTransaction;

      if (result) {
        // Pass transactiion to be watched
        const completeClaimBushChookCallback = () => {
          setSelectedBearsToClaim([]);
          updateBearsListing(userWalletAddress);
          setUpdateButtonText("Claim!");
          setIsLoadingClaimBushChook(false);
          setAlertType("success");
          setLoadingClaimMessage("");
          setAlertMessage(
            `You have successfully claimed a BushChook. Check out OpenSea!`
          );
          setShowAlert(true);
        };
        if (
          !!transactionDetails &&
          "hash" in transactionDetails &&
          !!transactionDetails.hash
        ) {
          watchTransaction(
            transactionDetails.hash,
            completeClaimBushChookCallback
          );
        }
      } else {
        // Show error message
        setUpdateButtonText("Claim!");
        setAlertMessage(message);
        setShowAlert(true);
        setLoadingClaimMessage("");
        setIsLoadingClaimBushChook(false);
      }
    }
  };

  const handleClickWallet = async () => {
    setIsLoadingWallet(true);
    const { result, message } = await connectMetamask();

    if (!result) {
      setAlertType("warning");
    } else {
      setAlertType("success");
    }
    setAlertMessage(message);
    setShowAlert(true);
    setIsLoadingWallet(false);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const handleClickAttemptClaim = (bearToAddId: number) => {
    const updatedSelectedBear = [...selectedBearsToClaim];
    const indexOfBearInSelectSet = selectedBearsToClaim.indexOf(bearToAddId);

    if (indexOfBearInSelectSet > -1) {
      updatedSelectedBear.splice(indexOfBearInSelectSet, 1);
    } else {
      updatedSelectedBear.push(bearToAddId);
    }

    setSelectedBearsToClaim(updatedSelectedBear);
  };

  const handleTextChanges = (e: any) => {
    let inputBearId = parseInt(e.target.value, 10);

    // if value is not blank, then test the regex
    if (inputBearId) {
      if (inputBearId >= 10000) {
        inputBearId = 9999;
      } else if (inputBearId < 0) {
        inputBearId = 0;
      }
      setCurrentCheckedBearId(inputBearId);
    }
  };

  let bearImage =
    isLoadingCheckBear || (currentCheckedBearImage && !isBearsImageLoaded)
      ? loadingBgImage
      : noBushChookImage;

  const shouldDisableCheckButton =
    !userWalletAddress || isLoadingCheckBear || !currentCheckedBearId;
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <div className="App">
        <header className="App-header">
          {!userWalletAddress ? (
            <div className="Wallet-section">
              <h1 className="Title TextOutline">No Wallet Found!</h1>

              <h2 className="ConnectMetamaskTitle TextOutline">
                Connect MetaMask to get started
              </h2>
              <div>
                <Button
                  disabled={isLoadingWallet}
                  className="SelectBackgroundButton"
                  variant="contained"
                  onClick={handleClickWallet}
                  size="large"
                >
                  <img
                    src={
                      isLoadingWallet
                        ? middleButtonImagePressed
                        : middleButtonImage
                    }
                    className="MiddleButtonImage"
                    alt="logo"
                  />
                  <span className="MiddleButtonText">
                    <span>Connect</span>
                    {isLoadingWallet && <LoadingElement color="white" />}
                  </span>
                </Button>
              </div>
            </div>
          ) : (
            <div className="Section-container">
              <div className="SectionPanel SectionPanel-left">
                <div className="Selector-CheckSection">
                  <h1 className="CheckTitle TextOutline">
                    Has a bear been claimed?
                  </h1>

                  <div className="Selector-section">
                    <h2 className="BearIdTitle">
                      <TextField
                        id="outlined-basic"
                        label="Bear ID"
                        variant="outlined"
                        value={currentCheckedBearId}
                        onChange={handleTextChanges}
                        disabled={!userWalletAddress || isLoadingCheckBear}
                        type="number"
                        className={
                          shouldDisableCheckButton
                            ? "BearIdTitle-input-disabled"
                            : ""
                        }
                      />
                    </h2>

                    <Button
                      disabled={shouldDisableCheckButton}
                      className="SelectBackgroundButton"
                      variant="contained"
                      onClick={handleCheckBearClick}
                      size="large"
                    >
                      <img
                        src={
                          shouldDisableCheckButton
                            ? middleButtonImagePressed
                            : middleButtonImage
                        }
                        className="MiddleButtonImage"
                        alt="logo"
                      />
                      <span className="MiddleButtonText">
                        <span>Check!</span>
                        {isLoadingCheckBear && <LoadingElement color="white" />}
                      </span>
                    </Button>
                  </div>
                </div>

                <div className="SectionListElement-container SectionListElement-image">
                  {currentCheckedBearImage && (
                    <>
                      <img
                        src={currentCheckedBearImage}
                        className="BackgroundImage-element"
                        alt="BackgroundImage"
                        onLoad={() => setIsBearsImageLoaded(true)}
                      />

                      <div
                        className={`BearText-status TextOutline BearText-status-${
                          !isBearClaimed ? "available" : "claimed"
                        }`}
                      >
                        {!isBearClaimed ? "Available ✓" : "claimed!"}
                      </div>
                    </>
                  )}
                  {(isLoadingCheckBear ||
                    !isBearsImageLoaded ||
                    !currentCheckedBearImage) && (
                    <>
                      <img
                        src={bearImage}
                        className="BearImage-element"
                        alt="BearImage"
                      />

                      {isLoadingCheckBear ||
                        (currentCheckedBearImage && !isBearsImageLoaded && (
                          <img
                            src={loadingKoalaImage}
                            className="BearImage-element"
                            alt="BearImage"
                          />
                        ))}
                    </>
                  )}
                </div>
              </div>

              <div className="SectionPanel SectionPanel-right">
                <div className="Selector-ToggleSection">
                  {!!userWalletAddress && (
                    <>
                      <h1 className="CheckTitle CheckTitle-claim TextOutline">
                        Claim A Bush Chook
                      </h1>
                      <h4 className="CheckTitle CheckTitle-wallet TextOutline">
                        <span>Wallet:</span>{" "}
                        {addressStringReduction(userWalletAddress)}
                      </h4>
                    </>
                  )}
                  <div
                    className={`SelectorList-container${
                      !!loadingClaimMessage
                        ? " SelectorList-loading_adjust"
                        : ""
                    }`}
                  >
                    <div className="SelectorList-section">
                      <div className="SectionListElement-container">
                        {isLoadingBears ? (
                          <h2 className="SectionList-placeholder TextOutline">
                            Loading Bears...
                          </h2>
                        ) : (
                          walletOwnersBears.map((currentBear, index) => (
                            <div
                              key={`bear-element-${index}`}
                              className={`SelectorList-element${
                                selectedBearsToClaim.indexOf(
                                  currentBear.bearId
                                ) > -1
                                  ? " SelectorList-selected"
                                  : ""
                              }`}
                            >
                              <div className="SelectorList-text">
                                <h2 className="SelectorList-title">
                                  {`Bear #${currentBear.bearId}`}
                                </h2>
                              </div>

                              {!currentBear.isClaimed ? (
                                <>
                                  {selectedBearsToClaim.indexOf(
                                    currentBear.bearId
                                  ) > -1 && (
                                    <>
                                      {isLoadingClaimBushChook && (
                                        <LoadingElement color="white" />
                                      )}
                                      <span className="SelectorList-added">
                                        {isLoadingClaimBushChook
                                          ? "Claiming"
                                          : "Added"}
                                      </span>
                                    </>
                                  )}
                                  <Button
                                    disabled={
                                      !userWalletAddress ||
                                      isLoadingClaimBushChook
                                    }
                                    className="SelectBackgroundButton"
                                    variant="contained"
                                    onClick={() =>
                                      handleClickAttemptClaim(
                                        currentBear.bearId
                                      )
                                    }
                                    size="large"
                                  >
                                    <img
                                      src={
                                        isLoadingClaimBushChook
                                          ? smallButtonImagePressed
                                          : smallButtonImage
                                      }
                                      className="SmallButtonImage"
                                      alt="logo"
                                    />

                                    <span className="MiddleButtonText">
                                      {selectedBearsToClaim.indexOf(
                                        currentBear.bearId
                                      ) > -1
                                        ? "-"
                                        : "+"}
                                    </span>
                                  </Button>
                                </>
                              ) : (
                                <span className="SelectorList-mini-text">
                                  Claimed
                                </span>
                              )}
                            </div>
                          ))
                        )}
                        {!isLoadingBears && walletOwnersBears.length === 0 && (
                          <h2 className="SectionList-placeholder TextOutline">
                            No Drop Bears in this Wallet
                          </h2>
                        )}
                      </div>
                    </div>
                  </div>

                  {!!loadingClaimMessage && (
                    <p className="LoadingText">{loadingClaimMessage}</p>
                  )}

                  <Button
                    disabled={
                      !userWalletAddress ||
                      isLoadingClaimBushChook ||
                      selectedBearsToClaim.length === 0
                    }
                    className="SelectBackgroundButton"
                    variant="contained"
                    onClick={handleClaimClick}
                    size="large"
                  >
                    <img
                      src={
                        isLoadingClaimBushChook ||
                        selectedBearsToClaim.length === 0
                          ? middleButtonImagePressed
                          : middleButtonImage
                      }
                      className="MiddleButtonImage"
                      alt="logo"
                    />
                    <span className="MiddleButtonText">
                      <span>{updateButtonText}</span>
                      {isLoadingClaimBushChook && (
                        <LoadingElement color="white" />
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </header>

        <div className="Footer-container">
          <div className="Footer-section">
            <img
              alt=""
              src={
                "https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611f1d4156f2c3a0cb80c2b5_Drop%20Bears%20Io%20PTY%20LTD.png"
              }
              className="image-18"
            />
          </div>

          <div className="Footer-section Text-center">
            <img
              alt=""
              src={
                "https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611f1cdef6dbed6d50b2cf2a_white_dropbears_M.png"
              }
              className="image-dropbears-logo"
            />
          </div>

          <div className="Footer-section Text-right">
            <a
              rel="noreferrer"
              href="https://twitter.com/DropBearsio"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                alt=""
                src={
                  "https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611c45a1d62fd14b04becc11_gorjeo%20(1).svg"
                }
                className="social-icon"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://discord.gg/PeC8VUNFgp"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                alt=""
                src={
                  "https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/612439a48640ce6a5d8cb623_Discord-Logo-White.svg"
                }
                width="63"
                className="social-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
